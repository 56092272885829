import { InferenceSession, Tensor } from "onnxruntime-web";
import React, { useContext, useEffect, useState, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import "./assets/scss/App.scss";
import { onnxMaskToImage } from "./components/helpers/maskUtils";
import { modelData } from "./components/helpers/onnxModelAPI";
/* @ts-ignore */

import "./assets/css/style.css";

import NavBar from "./pages/NavBar";

const SamOnnx = lazy(() => import('./pages/SamOnnx'));
const SegmentLoadProject = lazy(() => import('./pages/SegmentLoadProject'));
// const Contact = lazy(() => import('./pages/Contact'));

const App = () => 
  {
  return (
    <Router>
      <div>
      <NavBar />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/SamOnnx" element={<SamOnnx />} />
            <Route path="/SegmentLoadProject" element={<SegmentLoadProject />} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
