import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../assets/css/NavBar.css"; // Importiere die CSS-Datei für das Styling

const NavBar: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState<string | null>(null);

  const handleMenuToggle = (menu: string) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };

  return (
    <nav className="navbar">
      <ul className="navbar-top nav-list">
        <a href="/">
          <img className="logoNavBar" src="/pictures/global/Logo1.png"></img>
        </a>
        <li className="nav-item">
          <button className="nav-button" onClick={() => handleMenuToggle('kmps.image.ai')}>
            kmps.image.ai
          </button>
        </li>
        <li className="nav-item">
          <button className="nav-button" onClick={() => handleMenuToggle('kmps.image')}>
            kmps.image
          </button>
        </li>
        <li className="nav-item">
          <button className="nav-button" onClick={() => handleMenuToggle('kmps.data')}>
            kmps.data
          </button>
        </li>
      </ul>

      {activeMenu === 'kmps.image.ai' && (
        <div className="dropdown">
          <Link className="dropdownitem image-container" to="/SamOnnx">
            <img className="dropDownImage" src="/pictures/global/icon-grey-small.png"></img>
            <div className="label">SamOnnx</div>
          </Link>
          <Link className="dropdownitem image-container" to="/SegmentLoadProject">
            <img className="dropDownImage" src="/pictures/global/icon-grey-small.png"></img>
            <div className="label">SegmentLoadProject</div>
          </Link>
        </div>
      )}
      {activeMenu === 'kmps.image' && (
        <div className="dropdown">
          <Link className="dropdownitem image-container" to="/About1">
            <img className="dropDownImage" src="/pictures/global/icon-grey-small.png"></img>
            <div className="label">Lorem Ipsum 1</div>
          </Link>
          <Link className="dropdownitem image-container" to="/About1">
            <img className="dropDownImage" src="/pictures/global/icon-grey-small.png"></img>
            <div className="label">Lorem Ipsum 2</div>
          </Link>
        </div>
      )}
      {activeMenu === 'kmps.data' && (
        <div className="dropdown">
          <Link className="dropdownitem image-container" to="/1">
            <img className="dropDownImage" src="/pictures/global/icon-grey-small.png"></img>
            <div className="label">1</div>
          </Link>
          <Link className="dropdownitem image-container" to="/2">
            <img className="dropDownImage" src="/pictures/global/icon-grey-small.png"></img>
            <div className="label">2</div>
          </Link>
          <Link className="dropdownitem image-container" to="/3">
            <img className="dropDownImage" src="/pictures/global/icon-grey-small.png"></img>
            <div className="label">3</div>
          </Link>
          <Link className="dropdownitem image-container" to="/4">
            <img className="dropDownImage" src="/pictures/global/icon-grey-small.png"></img>
            <div className="label">4</div>
          </Link>
        </div>
      )}
    </nav>
  );
}

export default NavBar;
